import styled from "styled-components";

export const Hero = styled.div`
  background-color: ${props => props.theme.colors.black};
  padding: 88px 0 0;
  overflow:hidden;
  position: relative;

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    padding: 50px 0 0;
  }
`;

export const HeroInner = styled.div`
  max-width: ${props => props.theme.breakpoints.lg};
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  min-height: 400px;

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    margin: 0 20px;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  padding-right: 100px;
  padding-bottom: 80px;

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    padding-right: 40px;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
    text-align: center;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 16px;
  }

  h1 {
    color: ${props => props.theme.colors.white};
    margin-bottom: 16px;

    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      max-width: 480px;
    }

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      margin-top: 16px;
    }
  }

  p {
    color: ${props => props.theme.colors.white};

    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      max-width: 480px;
    }
  }
`;

export const ImageWrapper = styled.div`
  width: 50%;
  position: relative;
  margin-bottom: -20px;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-bottom: 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  img {
    width: 120%;
    height: auto;

    @media (max-width: ${props => props.theme.breakpoints.md}) {
      width: 130%;
      position: relative;
      top: 40px;
    }

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      top: 0;
      width: 100%;
      max-width: 480px;
    }
  }
`;