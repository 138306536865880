import React, { useMemo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "../ui-kits/Button";
import Divider from "../layout/Divider";
import * as S from "./Hero.styled";

import heroImageEn from "../../img/hero-image-en.svg";
import heroImagePl from "../../img/hero-image-pl.svg";

const Hero = () => {
  const { t, i18n } = useTranslation()
  const [language, setLanguage] = useState<string>();

  useEffect(() => {
    if (i18n && language !== i18n.language) {
      setLanguage(i18n.language);
    }
  }, [i18n])

  const heading = t("hero.heading");
  const heroImage = useMemo(() => language === "pl" ? heroImagePl : heroImageEn, [language]);

  return (
    <S.Hero>
      <S.HeroInner>
        <S.Content>
          <h1>{heading}</h1>
          <p>{t("hero.paragraph")}</p>
          {/* <Button to="/">{t("hero.cta")}</Button> */}
        </S.Content>
        <S.ImageWrapper>
          <img src={heroImage} alt={heading} title={heading} />
        </S.ImageWrapper>
      </S.HeroInner>
      <Divider color="gray5"/>
    </S.Hero>
  )
};

export default Hero;
