import React, { useEffect, useState } from "react";
import { PageProps } from "gatsby";
import { useTranslation } from "react-i18next";
import { goToAnchor, removeHash } from "react-scrollable-anchor";

import Layout from "../../components/layout/Layout";
import Hero from "../../components/home/Hero";
import Benefits from "../../components/home/Benefits";
import TwoColumnSection from "../../components/layout/TwoColumnSection";
import Testimonials from "../../components/home/Testimonials";

import featureImage0PL from "../../img/fast-registration-process-pl.svg";
import featureImage0EN from "../../img/fast-registration-process-en.svg";
import featureImage1 from "../../img/queue.svg";
import featureImage2PL from "../../img/personalize-pl.svg";
import featureImage2EN from "../../img/personalize-en.svg";
import featureImage3PL from "../../img/notifications-pl.svg";
import featureImage3EN from "../../img/notifications-en.svg";
import featureImage4PL from "../../img/reminders-pl.svg";
import featureImage4EN from "../../img/reminders-en.svg";
import featureImage5PL from "../../img/automated-schedule-optimization-pl.svg";
import featureImage5EN from "../../img/automated-schedule-optimization-en.svg";
import featureImage6PL from "../../img/build-optimal-journey-pl.svg";
import featureImage6EN from "../../img/build-optimal-journey-en.svg";
import featureImage7PL from "../../img/lead-generation-pl.svg";
import featureImage7EN from "../../img/lead-generation-en.svg";
import featureImage8 from "../../img/facebook-google-ai.svg";
import featureImage9PL from "../../img/retargetting-pl.svg";
import featureImage9EN from "../../img/retargetting-en.svg";

const HomePage = ({ location }: PageProps<any, any, { scrollTo?: string }>) => {
  const { t, i18n } = useTranslation();

  const [language, setLanguage] = useState<string>();

  useEffect(() => {
    if (i18n && language !== i18n.language) {
      setLanguage(i18n.language);
    }
  }, [i18n])

  useEffect(() => {
    if (location.state && location.state.scrollTo) {
      goToAnchor(location.state.scrollTo);
      removeHash();
    }
  }, [location])

  return (
    <Layout location={location}>
      <Hero />
      {/* <Benefits />
      <TwoColumnSection
        id="features"
        heading={t("features.0.heading")}
        paragraph={t("features.0.paragraph")}
        image={language === 'pl' ? featureImage0PL : featureImage0EN}
        backgroundColor="white"
      />
      <TwoColumnSection
        heading={t("features.1.heading")}
        paragraph={t("features.1.paragraph")}
        image={featureImage1}
        backgroundColor="white"
        dividerColor="gray5"
        reverse
      />
      <TwoColumnSection
        heading={t("features.2.heading")}
        paragraph={t("features.2.paragraph")}
        image={language === 'pl' ? featureImage2PL : featureImage2EN}
      />
      <TwoColumnSection
        heading={t("features.3.heading")}
        paragraph={t("features.3.paragraph")}
        image={language === 'pl' ? featureImage3PL : featureImage3EN}
        dividerColor="black"
        reverse
      />
      <Testimonials />
      <TwoColumnSection
        heading={t("features.4.heading")}
        paragraph={t("features.4.paragraph")}
        image={language === 'pl' ? featureImage4PL : featureImage4EN}
        backgroundColor="white"
      />
      <TwoColumnSection
        heading={t("features.5.heading")}
        paragraph={t("features.5.paragraph")}
        image={language === 'pl' ? featureImage5PL : featureImage5EN}
        backgroundColor="white"
        dividerColor="gray5"
        reverse
      />
      <TwoColumnSection
        heading={t("features.6.heading")}
        paragraph={t("features.6.paragraph")}
        image={language === 'pl' ? featureImage6PL : featureImage6EN}
      />
      <TwoColumnSection
        heading={t("features.7.heading")}
        paragraph={t("features.7.paragraph")}
        image={language === 'pl' ? featureImage7PL : featureImage7EN}
        reverse
        dividerColor="white"
      />
      <TwoColumnSection
        heading={t("features.8.heading")}
        paragraph={t("features.8.paragraph")}
        image={featureImage8}
        backgroundColor="white"
      />
      <TwoColumnSection
        heading={t("features.9.heading")}
        paragraph={t("features.9.paragraph")}
        image={language === 'pl' ? featureImage9PL : featureImage9EN}
        backgroundColor="white"
        dividerColor="gray5"
        reverse
      /> */}
    </Layout>
  );
};

export default HomePage;
